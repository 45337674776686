@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('./assets/fonts/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Microgramma-Bold';
  src: url('./assets/fonts/Microgramma-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Microgramma-Regular';
  src: url('./assets/fonts/Microgramma-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  color: #808080;
}

html {
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

/* Style the scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Adjust padding for scrollbar */
#root {
  box-sizing: border-box;
}

.burger-container {
  -webkit-tap-highlight-color: transparent; /* Prevent blue highlight on iOS */
  -webkit-touch-callout: none; /* Prevent callout menu on iOS */
  -webkit-user-select: none; /* Prevent copy paste on iOS */
  -moz-user-select: none; /* Prevent copy paste on Firefox */
  -ms-user-select: none; /* Prevent copy paste on IE */
  user-select: none; /* Prevent copy paste on Chrome, Opera, and Safari */
}
